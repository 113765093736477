import React, { useRef, useState } from 'react';
import '../Assets/Inicio.css'
import logo from '../logo-simulador-simon.png'
import clock from '../Clock.png';
import gun from '../gun.png';
import Imagenes from '../Components/Imagenes';
import recono1 from '../Reconocimiento_1.png';
import recono2 from '../Reconocimiento_2.png';
import recono3 from '../Reconocimiento_3.png';
import abajo from '../right-arrow.png';
import arriba from '../left-arrow2.png';
import component from '../Componente 7 – 5.png';
import component2 from '../Select_Submenu.png';
import { Link } from 'react-router-dom';
import Config from './Config';

const Inicio = () => {
  const imagenes = [
    { imagen: recono1, texto: 'Reconocimiento1', descripcion: 'Descripción de la imagen 1', index: '1' },
    { imagen: recono2, texto: 'Reconocimiento2', descripcion: 'Descripción de la imagen 2', index: '2' },
    { imagen: recono3, texto: 'Reconocimiento3', descripcion: 'Descripción de la imagen 3', index: '3' },
    { imagen: recono3, texto: 'Reconocimiento4', descripcion: 'Descripción de la imagen 4', index: '4' },
  ];

  const [imagenActual, setImagenActual] = useState(component);
  const [imagenActual2, setImagenActual2] = useState(component);
  const [imagenActual3, setImagenActual3] = useState(component);
  const [selectedText, setSelectedText] = useState('Seleccione Ejercicio');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedLink, setSelectedLink] = useState('');

  const handleImageClick = (texto, descripcion, index) => {
    setSelectedText(texto);
    setSelectedDescription(descripcion);
    setSelectedLink(index);
  };


  const scrollContainerRef = useRef();

  const scrollDown = () => {
    const container = scrollContainerRef.current;
    container.scrollTop += 100;
  };

  const scrollUp = () => {
    const container = scrollContainerRef.current;
    container.scrollTop -= 100;
  };
   
  console.log(selectedLink)
  return (
    <div className='Contenido'>
      <div className='Imagenes'>
        <div className='containerima'>
          <h2 className='Texto'>Seleccione el Ejercicio</h2>
          <hr className='separadorInit2' />
          <div className='scrollContainer'>
            <div className='imagenesScroll' ref={scrollContainerRef}>
              {imagenes.map((img, index) => (
                <Imagenes
                  key={index}
                  texto={img.texto}
                  imagen={img.imagen}
                  onClick={() => handleImageClick(img.texto, img.descripcion, img.index)}  
                />
              ))}
            </div>

            <div className='scrollButtons'>
              <button className='buttonlowefect' onClick={scrollUp}>
                <img src={arriba} alt="Subir" />
              </button>
              <button className='buttonlowefect' onClick={scrollDown}>
                <img src={abajo} alt="Bajar" />
              </button>
            </div>
          </div>
          <hr className='separadorInit2' />
        </div>
      </div>
      <div className='Descripcion'>
        <div>
          <h2 className='Texto2'>Descripcion del Ejercicio</h2>
          <hr className='separadorInit' />
        </div>
        <div className='contenidosInfo'>
          <div className='inforeloj'>
            <div className='estilosinfoContainer'>
              <img className='ImagenLogo2' src={clock} alt="Logo" />
              <h2>20"</h2>
            </div>
            <div className='estilosinfoContainer2'>
              <img className='ImagenLogo2' src={gun} alt="Logo" />
              <h2>15"</h2>
            </div>
          </div>
          <div className='TextoInfoExercise'>
            {selectedText !== 'Seleccione Ejercicio' ?
              <h2>{selectedText}</h2> :
              <h2>Seleccione Ejercicio</h2>
            }
            <h2 className='textodescri'>{selectedDescription}</h2>
          </div>

        </div>
      </div>
      <div className='BotonsSimon'>
        <div>
          <img className='ImagenLogo' src={logo} alt="Logo" />
        </div>
        <div>
        <Link
            to={{
              pathname: '/config',
            }}
          >
          <div
            className="image-container"
            onMouseEnter={() => setImagenActual(component2)}
            onMouseLeave={() => setImagenActual(component)}
          >
            <img src={imagenActual} alt="Descripción de la imagen" />
            <div className="text-over-image">Ajustes</div>
          </div>
          </Link>
          <Link
            to={{
              pathname: '/Loading',
              state: { link: selectedLink }
            }}
            onClick={e => {
              if (selectedLink === '') {
                e.preventDefault();
                alert('Por favor, seleccione un ejercicio antes de iniciar.');
              }
            }}
          >
            <div
              className="image-container"
              onMouseEnter={() => setImagenActual2(component2)}
              onMouseLeave={() => setImagenActual2(component)}
            >
              <img src={imagenActual2} alt="Descripción de la imagen" />
              <div className="text-over-image">Iniciar</div>
            </div>
          </Link>
          <div
            className="image-container"
            onMouseEnter={() => setImagenActual3(component2)}
            onMouseLeave={() => setImagenActual3(component)}
          >
            <img src={imagenActual3} alt="Descripción de la imagen" />
            <div className="text-over-image">Salir</div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Inicio