import React from 'react'

const Imagenes = ({ imagen, texto, onClick }) => {
  return (
    <div className="imagen-con-texto" onClick={onClick}>
      <div className="texto-sobre-imagen">{texto}</div>
      <img classname="imagencon" src={imagen} alt={texto} />
    </div>
  )
}

export default Imagenes;
