import React, { useState, useEffect } from 'react';
import '../Assets/config.css'
import { Link } from 'react-router-dom';
import component from '../Componente 7 – 5.png';
import component2 from '../Select_Submenu.png';
import logo from '../logo-simulador-simon.png'
import { CSSTransition } from 'react-transition-group';

const Config = () => {

  const [imagenActual, setImagenActual] = useState(component);
  const [imagenActual2, setImagenActual2] = useState(component);
  const [currentAnimation, setCurrentAnimation] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [currentIndex6, setCurrentIndex6] = useState(0);
  const [currentIndex7, setCurrentIndex7] = useState(0);
  const [currentIndex8, setCurrentIndex8] = useState(0);
  const [currentIndex9, setCurrentIndex9] = useState(0);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  const contents = [" Alto", "Medio ", "Bajo"];
  const contents2 = [" Alto", "Medio ", "Bajo"];
  const contents3 = [" Alto", "Medio ", "Bajo"];
  const contents4 = [" Alto", "Medio ", "Bajo"];
  const contents5 = [" 1280x720", "1920x1080", "2040x1920"];
  const contents6 = [" Alto", "Medio ", "Bajo"];
  const contents7 = [" Alto", "Medio ", "Bajo"];
  const contents8 = [" Alto", "Medio ", "Bajo"];
  const contents9 = [" 30 FPS", "60FPS ", "120FPS"];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  const handlePrevious2 = () => {
    setCurrentIndex2((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext2 = () => {
    setCurrentIndex2((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  const handlePrevious3 = () => {
    setCurrentIndex3((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext3 = () => {
    setCurrentIndex3((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  const handlePrevious4 = () => {
    setCurrentIndex4((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext4 = () => {
    setCurrentIndex4((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  const handlePrevious5 = () => {
    setCurrentIndex5((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext5 = () => {
    setCurrentIndex5((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  const handlePrevious6 = () => {
    setCurrentIndex6((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext6 = () => {
    setCurrentIndex6((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  }; const handlePrevious7 = () => {
    setCurrentIndex7((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext7 = () => {
    setCurrentIndex7((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  }; const handlePrevious8 = () => {
    setCurrentIndex8((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext8 = () => {
    setCurrentIndex8((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  }; const handlePrevious9 = () => {
    setCurrentIndex9((prevIndex) => (prevIndex === 0 ? contents.length - 1 : prevIndex - 1));
  };

  const handleNext9 = () => {
    setCurrentIndex9((prevIndex) => (prevIndex === contents.length - 1 ? 0 : prevIndex + 1));
  };
  return (
    <div className='background'>
      <div className='botons'>
        <Link
          to={{
            pathname: '/',

          }}
          onClick={e => {
          }}
        >
          <CSSTransition
            in={showContent}
            classNames="slide-in"
            timeout={500}
            onEntered={() => setCurrentAnimation(null)}
          >
            <div
              className="image-container"
              onMouseEnter={() => setImagenActual(component2)}
              onMouseLeave={() => setImagenActual(component)}
            >
              <img src={imagenActual} alt="Descripción de la imagen" />
              <div className="text-over-image2">Regresar</div>
            </div>
          </CSSTransition>
        </Link>
        <Link
          to={{
            pathname: '/',

          }}
          onClick={e => {
          }}
        ><CSSTransition
          in={showContent}
          classNames="slide-in"
          timeout={500}
          onEntered={() => setCurrentAnimation(null)}
        >
            <div
              className="image-container"
              onMouseEnter={() => setImagenActual2(component2)}
              onMouseLeave={() => setImagenActual2(component)}
            >
              <img src={imagenActual2} alt="Descripción de la imagen" />
              <div className="text-over-image2">Informacion</div>
            </div>
          </CSSTransition>
        </Link>
      </div>
      <div className="containerrrr">
        <div className="column3">
          <div className="content-container1">
            <CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            >
              <h2 className='colugrid'>Calidad Grafica</h2>
            </CSSTransition>
          </div>
          <div className="content-container1">
            <CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            >
              <h2 className='colugrid'>Suavizada</h2>
            </CSSTransition>
          </div>
          <div className="content-container1">
            <CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            >
              <h2 className='colugrid'>Texturas</h2>
            </CSSTransition>
          </div>
          <div className="content-container1">
            <CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            >
              <h2 className='colugrid'>Detalles</h2>
            </CSSTransition>
          </div>
          <div className="content-container1">
            <CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            >
              <h2 className='colugrid'>Resolucion</h2>
            </CSSTransition>
          </div>
        </div>
        <div className="column4">
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content " onAnimationEnd={() => setCurrentAnimation(null)}>
                  {contents[currentIndex]}
                </div></CSSTransition>
            </div>
            <div className="arrow right" onClick={handleNext}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious2}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents2[currentIndex2]}
                </div>
              </CSSTransition></div>
            <div className="arrow right" onClick={handleNext2}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious3}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents3[currentIndex3]}
                </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext3}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious4}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents4[currentIndex4]}
                </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext4}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious5}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents5[currentIndex5]}
                </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext5}>
              &gt;
            </div>
          </div>
        </div>
        <div className="column3">
          <div className="content-container">
            <h2 className='colugrid'>Postprocesamiento</h2>
          </div>
          <div className="content-container">
            <h2 className='colugrid'>Sombras</h2>
          </div>
          <div className="content-container">
            <h2 className='colugrid'>Efectos</h2>
          </div>
          <div className="content-container">
            <h2 className='colugrid'>FPS</h2>
          </div>
        </div>
        <div className="column4">
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious6}>
              &lt;
            </div>
            <div className="content-wrapper"><CSSTransition
              in={showContent}
              classNames="slide-in"
              timeout={500}
              onEntered={() => setCurrentAnimation(null)}
            ><div className="content">
                {contents6[currentIndex6]}
              </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext6}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious7}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              ><div className="content">
                  {contents7[currentIndex7]}</div></CSSTransition>
            </div>
            <div className="arrow right" onClick={handleNext7}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious8}>
              &lt;
            </div> <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents8[currentIndex8]}
                </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext8}>
              &gt;
            </div>
          </div>
          <div className="content-container">
            <div className="arrow left" onClick={handlePrevious9}>
              &lt;
            </div>
            <div className="content-wrapper">
              <CSSTransition
                in={showContent}
                classNames="slide-in"
                timeout={500}
                onEntered={() => setCurrentAnimation(null)}
              >
                <div className="content">
                  {contents9[currentIndex9]}
                </div></CSSTransition></div>
            <div className="arrow right" onClick={handleNext9}>
              &gt;
            </div>
          </div>
        </div>
      </div>
      <div className='iconoAbajo'>
        <CSSTransition
          in={showContent}
          classNames="slide-in"
          timeout={500}
          onEntered={() => setCurrentAnimation(null)}
        >
          <img className='ImagenLogo' src={logo} alt="Logo" />
        </CSSTransition>
      </div>
    </div >
  )
}

export default Config