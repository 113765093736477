import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../Assets/Loading.css'
import component from '../Componente 7 – 5.png';
import component2 from '../Select_Submenu.png';

const EjerciInit = () => {
  const [imagenActual, setImagenActual] = useState(component);
  const navigate = useNavigate();

  const location = useLocation();
  const link = location.state?.link;


  console.log(location)
  const handleClick = () => {
    navigate('/video', { state: { link: link } });
  };

  console.log({ link })

  return (
    <div className='mainContainer'>
      <div className='container'>
        <div className='contenido'>
          <h2 className='titulo'>EJERCICIO</h2>
          <h2 className='descripcion'>En el ejercicio de reconocimento debera realizar 15 disparo  a la silueta, tendra
            30 segundos para finalizar
          </h2>
        </div>
        <div
          className="image-container"
          onMouseEnter={() => setImagenActual(component2)}
          onMouseLeave={() => setImagenActual(component)}
          onClick={handleClick}
        >
          <img src={imagenActual} alt="Descripción de la imagen" />
          <div className="text-over-image">Iniciar</div>
        </div> </div>
    </div>
  )
}

export default EjerciInit
