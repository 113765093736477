import React, { useState, useEffect, useRef } from 'react';
import video1 from '../Assets/SIMON - Unreal Editor 2023-05-13 12-47-20 (online-video-cutter.com).mp4';
import video2 from '../Assets/SIMON - Unreal Editor 2023-05-13 12-49-27 (online-video-cutter.com).mp4';
import video3 from '../Assets/SIMON - Unreal Editor 2023-05-13 13-16-28 (online-video-cutter.com).mp4';
import { Link } from 'react-router-dom';

const videos = [video1, video2, video3];

const VideoPage = () => {
  const [showOptions, setShowOptions] = useState(false);
  const videoRef = useRef();

  // Selecciona un video aleatorio
  const videoUrl = videos[Math.floor(Math.random() * videos.length)];

  const requestFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setShowOptions(prevShowOptions => !prevShowOptions);
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <div style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        controls
        autoPlay
        muted
        style={{ objectFit: 'cover' }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showOptions && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}>
          <Link to={{ pathname: '/Loading' }}>
            <button onClick={() => ('')}>Opción 1</button>
          </Link>
          <button onClick={() => alert('Opción 2 seleccionada')}>Opción 2</button>
        </div>
      )}
      <button onClick={requestFullscreen}>Pantalla completa</button>
    </div>
  );
};

export default VideoPage;
