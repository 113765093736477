import './App.css';
import Inicio from './View/Inicio';
import Loading from '../src/View/EjerciInit'
import VideoPage from './View/VideoPage';
import Config from './View/Config';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Inicio/>} />
        <Route path="/Loading" element={<Loading />} />
        <Route path="/video" element={<VideoPage/>}/>
        <Route path="/config" element={<Config/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
